<template>
  <div class="blocked">
      <DashboardHeader page="Blocked"/>
      <div class="header">
        <Header/>
      </div>
      <div class="entry">
        <Entry :users="users"/>
      </div>
  </div>
</template>

<script>
import DashboardHeader from '@/components/DashboardHeader.vue'
import Header from './components/Header.vue'
import Entry from './components/Entry.vue'
import axios from 'axios'

export default {
  components: {
    DashboardHeader,
    Header,
    Entry
  },
  created() {
    axios.get(`${this.$store.state.auth.apiUrl}/api/User/get-block-users?secondaryCategoryId=${this.$store.state.auth.user.secondaryCategoryId}`)
    .then(res => {
      this.users = res.data.data
      console.log(res.data)
    })
    .catch(err => {
      console.log(err)
    })
  },
  data() {
    return {
      users: []
    }
  }
}
</script>

<style scoped>
    .blocked {
        padding: 0 10px;
    }

    .header {
      margin: 4rem 0  2rem 0;
    }
</style>