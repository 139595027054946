<template>
  <button :style="style" v-html="text">
  </button>
</template>

<script>
export default {
    props: ['text', 'style']
}
</script>

<style>
    button {    
        padding: 7px;
        border-radius: 3px;
        border: none;
        outline: none;
        font-weight: bold;
        font-family: inherit;
        cursor: pointer;
    }
</style>