<template>
  <div class="details flex-center">
    <div class="info">
        <div class="image-holder">
            <img :src="user.img">
        </div>

        <div class="text">
            <div class="name">{{ user.name }}</div>
            <div class="tag">@{{ user.tagName }}</div>
        </div>

        <div class="role">
            <b>{{ user.role }}</b>
        </div>

        <div class="records">
            <div class="record-item flex">
                <div class="div">Contact information</div>
                <div class="icon">
                    <i class="fa fa-angle-right"></i>
                </div>
            </div>
            <div class="record-item flex">
                <div class="div">Number of entry</div>
                <div class="icon">
                    <span class="entry-count">{{ user.entryCount }}</span> <i class="fa fa-angle-right"></i>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props: ['user']
}
</script>

<style scoped>
    .details {
        background-color: #fff;
        border-radius: 20px;
        height: 500px;
    }

    .image-holder {
        width: 130px;
        height: 130px;
        border-radius: 100%;
        overflow: hidden;
        margin: 0 auto;
        margin-bottom: 10px;
    }

    .text {
        text-align: center;
        margin-bottom: 20px;
    }

    .text .name {
        font-weight: bold;
    }

    .text .tag {
        font-size: 13px;
        color: #979797;
    }

    img {
        width: 100%;
    }

    .role {
        margin-bottom: 40px;
        text-align: center;
    }

    .records {
        width: 300px;
        font-size: 14px;
    }

    .record-item {
        margin-bottom: 30px;
        font-weight: 500;
    }
</style>