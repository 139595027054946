import axios from 'axios'
import router from '../router/index'

export default {
    state: {
        token: null,
        userId: null,
        apiUrl: 'http://linkmeupsecv2-001-site1.btempurl.com',
        user: {
                firstName: '',
                lastName: '',
                pictureUrl: ''
        },
        loginError: "",
        loginSuccess: "",
        fetchError: "",
        loading: false,
        editSecondaryProfile: false,
        generalMessage: ""
      },
      getters: {
            user(state){
                return state.user;
            },
            isAuthenticated(state) {
                return state.token !== null;
            }
        },
      mutations: {
        loginUser(state, authData){
            state.userId = authData.userId
            state.token = authData.token
            state.user = authData.user
        },
        fetchUserData(state, data) {
          state.user = data.user
        },
        logout(state) {
          state.user = {},
          state.loginSuccess = ""
          state.userId = "",
          state.token = ""
        }
      },
      actions: {
        login({commit, state}, authData) {
          state.loading = true
            axios.post('http://linkmeupsecv2-001-site1.btempurl.com/api/Secondary/Account/login', authData)
            .then((res) => {
              state.loginSuccess = "Login successful"
              state.generalMessage = { type: 1, text: "Login successful!" }
              state.loginError = ""
              state.loading = false
              setTimeout(() => {
                state.generalMessage = ""
              }, 3000)

              commit('loginUser', {
                token: res.data.data.token.bearerToken,
                userId: res.data.data.appUser.id,
                user: res.data.data.appUser,
              })

              localStorage.setItem("userId", res.data.data.appUser.id)
              localStorage.setItem("token", res.data.data.token.bearerToken)
              localStorage.setItem("user", JSON.stringify(res.data.data.appUser))

              router.replace('/dashboard');
            })
            .catch(err => {
              state.loginError = `[Login failed] ${err.response?.data.ShortDescription ? err.response?.data.ShortDescription : err }`
              state.loginSuccess = ""
              state.loading = false
              console.log(err)
            })
          },
          fetchUser({commit, state}) {
            if(localStorage.getItem("token")) {
              axios.get(`${state.apiUrl}/api/User/user-profile/${localStorage.getItem("userId")}`)
              .then(res => {
                localStorage.setItem("user", JSON.stringify(res.data.data))
                commit('fetchUserData', {
                  user: res.data.data,
                })
              })
              .catch(err => {
                state.loginError = `[failed] ${err}`
                console.log(err)
              })
            }
          },
          autoLogin({commit}) {
            const token = localStorage.getItem("token")
            const userId = localStorage.getItem("userId")
            const user = JSON.parse(localStorage.getItem("user"))
            commit('loginUser', {
              token,
              userId,
              user
            })
          },
          logoutUser({ commit }) {
            localStorage.removeItem("token")
            localStorage.removeItem("userId")
            localStorage.removeItem("user")
            commit('logout');
            router.replace('/security/login');
          }
      }
}