<template>
  <header>
      <div class="left">
          <div class="top-left">
              <p><span class="emoji">👋🏾</span> Welcome {{ user.firstName }}!</p>
          </div>

          <div class="bottom-left"> 
                <h1>{{ page }}</h1>
                <div class="flex">
                    <span class="date">{{ dayOfWeek }}</span>
                <span class="day">{{ today.toLocaleDateString("en-US", options) }}</span>
                </div>
          </div>
      </div>

      <div class="right">
          <div class="header-image">
              <Image/>
          </div>
          <div class="qr-code-spot">
              <div class="qr-code-box">
                <div class="image-holder-large flex-center">
                    <img :src="user.pictureUrl" alt="profile-photo">
                </div>
              </div>
          </div>

          <div class="user">
            <div>
                <h3 class="name-display">{{ user.lastName }} {{ user.firstName }}</h3>
                <p class="user-text">{{ user.userRole }}</p>
            </div>
            <Button @click="$router.push('/dashboard/qrcode')" :style="{ marginTop: '10px', color: '#159661', backgroundColor: '#fff', width: '130px', borderRadius: '8px', padding: '10px' }" text="Show QR Code"/>
        </div>

        <div class="connect">
            <img src="../assets/img/connect.png" width="180">
        </div>
      </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from '@/components/Button.vue'

export default {
    props: {
        page: String
    },
    computed: {
        ...mapGetters([
            'user',
        ])
    },
    data() {
      return {
        // img: "https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        today: new Date,
        dayOfWeek: new Date().getDate(),
        options: { weekday: 'long', month: 'long', year: 'numeric'}
      }
    },
    components: {
    //   QrcodeVue,
      Button
    },
    created() {
        this.$store.dispatch('fetchUser')
    }
}
</script>

<style scoped>
    header {
        background: url('../assets/img/header-pic.png') 0 -200px;
        background-size: cover;
        color: #fff;
        display: flex;
        justify-content: space-between;
        height: 200px;
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
        padding: 0.5rem 4rem;
        box-shadow: 0 0 6px 6px #ccc;
        position: relative;
    }

    .user {
        position: absolute;
        bottom: 10px;
        right: 27%;
        border-bottom-left-radius: 30px;
        font-size: 12px;
        font-weight: 500;
    }

    .connect {
        bottom: 0px;
        right: 40px;
        position: absolute;
    }

    .user-text {
        font-weight: 500;
    }

    h1 {
        font-size: 2.6rem;
        margin-bottom: -15px;
    }

    .top-left p {
        padding: 8px 0;
        font-size: 15px;
        text-transform: capitalize;
    }

    .bottom-left {
        margin-top: 0px;
        font-weight: 600;
    }

    .flex {
        width: 200px;
    }
    
    .qr-code-spot, .qr-code-box {
        background-color: #f3f3f3;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .qr-code-box {
        width: 170px;
        height: 170px;
        cursor: pointer;
    }

    .qr-code-spot {
        background: #f3f3f3;
        position: absolute;
        bottom: -60px;
        width: 200px;
        height: 200px;
        right: 41%;
    }

    .right {
        display: flex;
        align-items: center;
        padding: 10px;
    }

    .qr-text {
        font-size: 18px;
        text-align: center;
        padding-bottom: 10px;
    }

    .date {
        font-weight: bold;
        font-size: 3rem;
    }

    .day {
        width: 150px;
        margin-left: 10px;
        line-height: 1em;
        font-size: 15px;
    }

    .image-holder-large {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        margin: 0 auto;
        background-color:#DDF3FF;
        background-size: cover;
        overflow: hidden;
        text-align: center;
        border: 2px solid #fff;
        color: #000;
        text-align: center;
    }

    .image-holder-large img {
        width: 100%;
    }

    .emoji {
        font-size: 20px;
    }

    .name-display {
        text-transform: capitalize;
    }

    @media(max-width: 1200px) {
        .connect {
            right: 10px;
        }

        .user {
            right: 24%;
        }

        header {
            padding: 10px 20px;
        }
    }
</style>