<template>
  <div class="qrcode-page flex-center" @click="closeModal">
    <div class="modal-content" id="modal-content">
      <div class="top">
        <div class="close-btn flex-center">&times;</div>
      </div>
      
      <div class="body scroll">
          <h3>Paycaps project meetings</h3>

        <div class="details">
            <div class="date">Monday, 24 January 2022</div>
            <div class="time">13:05pm - 9:45pm</div>
            <div class="reminder">Reminder 10 mins</div>
            <div class="location">
                <div class="address">
                    <i class="fa fa-map-marker"></i> LMU, HQ 23344 Avenre Avenue, Ney York
                </div>
                <div class="map">

                </div>
            </div>
        </div>

        <div class="meeting">
            <div class="type">
                <i class="fa fa-video-camera"></i> Join Zoom meeting
            </div>
            <div class="link">
                <a href="https://us04web.zoom.us/j/8554945894?pwd=cYeDmndmbdd" target="_blank">
                    https://us04web.zoom.us/j/8554945894?pwd=cYeDmndmbdd
                </a>
            </div>
            <div class="extra">
                <div>
                    Meeting ID: 785 8478 4535
                </div>
                <div>Passcode: 4583Vdr9</div>
            </div>
        </div>

        <div class="note">
            <h2>Note</h2>
            <p class="info">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis, vitae optio! Quibusdam quam doloremque adipisci laborum. Dolorum, consequatur natus tempore tempora impedit soluta dicta perspiciatis id possimus odit quod iure.
            </p>
        </div>

        <div class="attendance">
            <h3 @click="showMembers = !showMembers">Attendee: 3 <i class="fa fa-angle-down" style="margin-left: 20px;"></i></h3>
            <div style="margin-bottom: 10px; font-size: 13px; font-weight: 500" v-if="showMembers">1 accepted, 1 waiting, 1 declined</div>
            
            <div class="members" v-if="showMembers">
                <div class="member" v-for="(member, index) in members" :key="index">
                    <div class="image-holder">
                        <img :src="member.img" alt="image">
                    </div>

                    <div class="status flex-center" v-if="member.status == 'in'" style="background-color: green">
                        <i class="fa fa-check"></i>
                    </div>

                    <div class="status flex-center" v-if="member.status == 'out'" style="background-color: red">
                        <i class="fa fa-times"></i>
                    </div>

                    <div class="name">
                        <span class="member-name">{{ member.name }}</span>
                        <span class="host" v-if="member.host">Organiser</span>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    
  },
  data(){
    return {
      classes: ['qrcode-page flex-center', 'close-btn flex-center', 'fa fa-times'],
      showMembers: true,
      members: [
                { img: 'https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500', name: "Nonso Godfrey", tagName: "nonsogodgrey", host: true, status: "pending",},
                { img: 'https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500', name: "Jack Dorsey", tagName: "jack", host: false, status: "in"},
                { img: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8bWVufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60', name: "Elon Musk", tagName: "elonmusk", host: false, status: "out"}
        ],
    }
  },
  methods: {
    closeModal(e){
      if(this.classes.includes(e.target.className)){
        this.$router.push('/dashboard')
      }
    }
  }
}
</script>

<style scoped>
    .qrcode-page {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
      overflow: auto;
      width: 100%;
      background: rgba(0,0,0,0.7);
      padding: 20px 0px;
    }

    .modal-content {
      height: 630px;
      width: 500px;
      background: #fff;
      color: #262626;
      border-radius: 30px;
      padding: 20px;
      box-shadow: 0 0 6px 6px #00000035;
      /* margin-top: 10rem; */
    }

    .top {
      text-align: right;
      display: flex;
      justify-content: right;
    }

    .close-btn {
      height: 20px;
      width: 20px;
      font-size: 15px;
      border-radius: 100px;
      text-align: center;
      cursor: pointer;
      text-align: right;
      background-color: rgba(0, 0, 0, 0.152);
    }

    .close-btn:hover {
      background-color: rgba(0, 0, 0, 0.042);
    }

    .body {
        height: 95%;
        overflow: auto;
        padding: 0 40px;
    }

    .body > div {
        margin-bottom: 30px;
    }

    .body h3 {
        margin-bottom: 30px;
    }

    .location .address {
        margin-bottom: 5px;
    }

    .map {
        height: 200px;
        width: 90%;
        border: 1px solid #ccc;
        border-radius: 20px;
    }

    /* note */
    .note h2 {
        /* font-weight: 600; */
        font-size: 17px;
    }
    
    .note p {
        font-size: 12px;
        font-weight: 500;
        width: 250px;
        text-align: left;
    }

    .image-holder {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        overflow: hidden;
    }

    .image-holder img {
        width: 100%;
    }

    .member {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        font-size: 14px;
        position: relative;
    }

    .status {
        position: absolute;
        height: 15px;
        width: 15px;
        font-size: 11px;
        font-weight: 700;
        border-radius: 100%;
        color: #fff;
        left: 40px;
        bottom: 0;
    }

    .member-name {
        display: block;
        font-size: 14px;
    }

    .member .host {
        font-size: 13px;
        font-weight: 500;
    }

    .name {
        margin-left: 20px;
        font-weight: 600;
    }

    /* meeting */
    .link {
        width: 250px;
        padding: 0 20px;
    }

    .link a {
        color: #4c9de9;
        font-size: 13px;
        text-decoration: none;
        font-weight: 500;
    }

    .meeting .extra {
        font-size: 14px;
        font-weight: 500;
        padding: 0 20px;
    }

    /* details */
    .details > div {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
    }

    
    .scroll::-webkit-scrollbar {
        width: 0.2em;
    }
    
    .scroll::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    outline: none;
    }

    .attendance h3 {
        cursor: pointer;
    }
</style>