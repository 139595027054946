<template>
  <div class="history">
      <DashboardHeader page="History"/>

      <div class="stats">
        <Stats v-on:sort-list="sortEntries"/>
      </div>

      <div class="header">
        <Header v-on:toggle-filter="show = !show" :entries="users"/>
      </div>

      <div class="flex">
        <div class="entry">
          <div class="flex pad">
            <p></p>
            <p>All entries</p>
          </div>
          <Entry :users="users" v-on:show-data="showData"/>
        </div>
        <div class="filter" v-if="showDetails">
          <div class="text pad">Filter</div>
          <Details :user="user"/>
        </div>
      </div>
  </div>
</template>

<script>
import DashboardHeader from '@/components/DashboardHeader.vue'
import Header from './components/Header.vue'
import Entry from './components/Entry.vue'
import Details from './components/Details.vue'
import Stats from '../dashboard/components/Stats.vue'

export default {
  components: {
    DashboardHeader,
    Header,
    Entry,
    Details,
    Stats
  },
  data(){
    return {
      users: [
          { img: 'https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500', name: "Nonso Godfrey", tagName: "nonsogodgrey", role: 'guest', entryCount: 4},
          { img: 'https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500', name: "Jack Dorsey", tagName: "jack", role: 'staff', entryCount: 3},
          { 
              img: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8bWVufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60', 
              name: "Elon Musk", tagName: "elonmusk", role: 'Guest', entryCount: 2
          }
      ],
      allUsers: [
          { img: 'https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500', name: "Nonso Godfrey", tagName: "nonsogodgrey", role: 'guest', entryCount: 4},
          { img: 'https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500', name: "Jack Dorsey", tagName: "jack", role: 'staff', entryCount: 3},
          { 
              img: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8bWVufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60', 
              name: "Elon Musk", tagName: "elonmusk", role: 'Guest', entryCount: 2
          }
      ],
      showDetails: false,
      user: {}
    }
  },
  methods: {
    showData(user) {
          this.user = user
          this.showDetails = true
    },
    sortEntries(status) {
      if(status === 'all') {
          return this.users = this.allUsers;
      }
      this.users = this.allUsers.filter(entry => entry.status === status)
    }
  }
}
</script>

<style scoped>
    .history {
        padding: 0 10px;
        min-height: 100vh;
        padding-bottom: 2rem;
    }

    .flex {
      align-items: stretch;
    }

    .entry {
      flex: 3;
      margin-right: 10px;
    }

    .filter {
      flex: 1.5;
    }
    
    .stats {
      margin-top: 3.5rem;
    }

    .pad {
      padding: 0px 50px 5px 20px;
      font-size: 13px;
      font-weight: 500;
    }
</style>