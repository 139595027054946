<template>
    <div class="search">
        <div class="flex">
          <div class="fake-input">
              <input type="text" placeholder="Search">
              <i class="fa fa-search"></i>
          </div>
        </div>

        <div class="right">
            <!-- <i class="fa fa-bell"></i> -->
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .search {
        display: flex;
        width: 95%;
        margin: 2% auto;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .fake-input {
        background: #fff;
        /* padding: 10px; */
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        width: 350px;
        padding: 0 10px;
        color: #ccc;
        margin-left: 20px;
    }

    input {
        padding: 10px;
        font-size: 15px;
        border: none;
        font-family: inherit;
        outline: none;
    }

    .fa-bell {
        font-size: 20px;
    }
</style>