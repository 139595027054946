import { createRouter, createWebHistory } from "vue-router";

import View from '../routes/dashboard/View.vue'
import Profile from "../routes/profile/Profile.vue"
import Directory from "../routes/directory/Directory.vue"
import Users from "../routes/users/Users.vue"
import History from '../routes/history/History.vue'
import Blocked from '../routes/blocked/Blocked.vue'
import Subscription from '../routes/subscription/Subscription.vue'
import Settings from '../routes/settings/Settings.vue'
import QRcode from '../routes/qrcode/QRcode.vue'
import Appointment from '../routes/dashboard/components/Appointment.vue'
import Notification from '../routes/notification/Notification.vue'

import store from '../store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () =>
        import("../views/Individual/Home.vue"),
    beforeEnter(to, from, next) {
      if(store.state.auth.token) {
        next('/dashboard')
      } else {
        next()
      }
    },
    meta: {
      publicRoute: true,
      title: `| Everyday made better!`,
      icon:"/favicon.ico",
      metaTags: [
       {
         name: 'description',
         content: 'Linkmeup security is a multifunctional application that provides a robust end-to-end access control and security management system.'
       },
       {
         property: 'og:description',
         content: 'Linkmeup security is a multifunctional application that provides a robust end-to-end access control and security management system.'
       }
      ]
    }
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () =>
        import("../views/privacy-policy/PrivacyPolicy.vue"),
    meta: {
      publicRoute: true,
      title: `| Linkmeup`,
      icon:"/favicon.ico",
      metaTags: [
       {
         name: 'description',
         content: 'Linkmeup security is a multifunctional application that provides a robust end-to-end access control and security management system.'
       },
       {
         property: 'og:description',
         content: 'Linkmeup security is a multifunctional application that provides a robust end-to-end access control and security management system.'
       }
      ]
    }
  },
  {
    path: '/security',
    name: 'Security',
    component: () => import('../views/security/Home.vue'),
    beforeEnter(to, from, next) {
      if(store.state.auth.token) {
        next('/dashboard')
      } else {
        next()
      }
    },
    children: [
      {
        path: "/security/login",
        name: "login",
        component: () =>
        import("../routes/auth/Login.vue"),
        beforeEnter(to, from, next) {
          if(store.state.auth.token) {
            next('/dashboard')
          } else {
            next()
          }
        }
      },
      {
        path: "/security/signup",
        name: "signup",
        component: () =>
        import("../routes/auth/Signup.vue"),
        beforeEnter(to, from, next) {
          if(store.state.auth.token) {
            next('/dashboard')
          } else {
            next()
          }
        }
      },
      {
        path: "/security/forgot-password",
        name: "ForgotPassword",
        component: () =>
        import("../routes/auth/ForgotPassword.vue"),
        beforeEnter(to, from, next) {
          if(store.state.auth.token) {
            next('/dashboard')
          } else {
            next()
          }
        }
      }
    ],
    meta: {
      publicRoute: true,
      title: `| Security`,
      icon:"/favicon2.ico",
      metaTags: [
       {
         name: 'description',
         content: 'Linkmeup security secures your homes, estates, offices, schools, and businesses. Keep an eye on everyone going in or coming out of your environment.'
       },
       {
         name: 'og:description',
         content: 'Linkmeup security secures your homes, estates, offices, schools, and businesses. Keep an eye on everyone going in or coming out of your environment.'
       },
       {
         name: 'theme-color',
         content: '#159661'
       }
      ],
    }
  },
  {
    path: "/app",
    name: "app",
    component: () =>
      import("../routes/dashboard/Dashboard.vue"),
    children: [
      {
        path: '/dashboard',
        name: "View",
        component: View,
        beforeEnter(to, from, next) {
          if(store.state.auth.token) {
            next()
          } else {
            next('/security/login')
          }
        },
        children: [
          {
            path: "/dashboard/qrcode",
            name: "qrcode",
            component: QRcode,
          },
          {
            path: "/dashboard/appointment",
            name: "appointment",
            component: Appointment,
          }
        ]
      },
      {
        path: "/profile",
        name: "profile",
        component: Profile,
        beforeEnter(to, from, next) {
          if(store.state.auth.token) {
            next()
          } else {
            next('/security/login')
          }
        },
        children: [
          {
            path: "/profile/edit",
            name: "editProfile",
            component: () =>
            import("../routes/profile/components/Edit.vue"),
            beforeEnter(to, from, next) {
              if(store.state.auth.editSecondaryProfile) {
                next()
              } else {
                store.state.auth.generalMessage = { type: 0, text: "Unauthorized route" }
                setTimeout(() => {
                  store.state.auth.generalMessage = ""
                }, 3000);
                next('/profile')
              }
            }
          }
        ]
      },
      {
        path: "/directory",
        name: "directory",
        component: Directory,
        beforeEnter(to, from, next) {
          if(store.state.auth.token) {
            next()
          } else {
            next('/security/login')
          }
        }
      },
      {
        path: "/users",
        name: "users",
        component: Users,
        beforeEnter(to, from, next) {
          if(store.state.auth.token) {
            next()
          } else {
            next('/security/login')
          }
        },
        children: [
          {
            path: "/users/edit",
            name: "editUser",
            component: () =>
            import("../routes/users/components/Edit.vue"),
          },
          {
            path: "/users/add",
            name: "addUser",
            component: () =>
            import("../routes/users/components/AddUser.vue"),
          }
        ]
      },
      {
        path: "/orders",
        name: "orders",
        component: () =>
        import("../routes/orders/Orders.vue"),
        beforeEnter(to, from, next) {
          if(store.state.auth.token) {
            next()
          } else {
            next('/security/login')
          }
        },
        children: [
          {
            path: "/orders/cart",
            name: "cart",
            component: () =>
            import("../routes/orders/components/Cart.vue"),
          },
          {
            path: "/orders/directory",
            name: "orderDirectory",
            component: () =>
            import("../routes/orders/components/Directory.vue"),
          },
          {
            path: "/orders/checkout",
            name: "checkout",
            component: () =>
            import("../routes/orders/components/Checkout.vue"),
          }
        ]
      },
      {
        path: "/history",
        name: "history",
        component: History,
        beforeEnter(to, from, next) {
          if(store.state.auth.token) {
            next()
          } else {
            next('/security/login')
          }
        },
      },
      {
        path: "/blocked",
        name: "blocked",
        component: Blocked,
        beforeEnter(to, from, next) {
          if(store.state.auth.token) {
            next()
          } else {
            next('/security/login')
          }
        },
      },
      {
        path: "/subscription",
        name: "subscription",
        component: Subscription,
        beforeEnter(to, from, next) {
          if(store.state.auth.token) {
            next()
          } else {
            next('/security/login')
          }
        },
      },
      {
        path: "/settings",
        name: "settings",
        component: Settings,
        beforeEnter(to, from, next) {
          if(store.state.auth.token) {
            next()
          } else {
            next('/security/login')
          }
        },
      },
      {
        path: "/notification",
        name: "notification",
        component: Notification,
        beforeEnter(to, from, next) {
          if(store.state.auth.token) {
            next()
          } else {
            next('/security/login')
          }
        },
      },
      {
        path: "/contacts",
        name: "contact",
        component: () =>
        import("../routes/contact/Contact.vue"),
        beforeEnter(to, from, next) {
          if(store.state.auth.token) {
            next()
          } else {
            next('/security/login')
          }
        },
      },
      {
        path: "/:pathMatch(.*)*",
        name: "notFound",
        component: () =>
        import("../routes/404/404.vue"),
      },
    ]
  }
];

const router = createRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,
  history: createWebHistory(process.env.BASE_URL)
});

export default router;
