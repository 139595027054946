import axios from 'axios'
import store from './index'

export default {
    state: {
        url: "http://linkmeupsecv2-001-site1.btempurl.com",
        users: []
    },
    getters: {
        directoryUsers(state) {
            return state.users
        }
    },
    mutations: {
        getUsersData(state, data){
            state.users = data.users
        }
    },
    actions: {
        fetchDirectoryUsers({commit, state}) {
            axios.get(`${state.url}/api/User/get-all-users-in-a-secondary-category?secondaryCategoryId=${store.state.auth.user.secondaryCategoryId}`)
            .then(res => {
                commit('getUsersData', {
                    users: res.data.data
                })
            })
            .catch(err => console.log(err))
        }
    }
}