<template>
    <div class="appointment" style="position: relative">
        <div class="top">
            <div @click="$emit('go-back')" style="cursor: pointer; position: absolute; left: 20px; top: 29px;">
                <i class="fa fa-angle-left"></i>
            </div>
            <h4 class="align-center">Resident Enrollment</h4>
        </div>
        <div class="contain">
            <div class="picture">
          <div class="image-holder">
              <img :src="user.pictureUrl">
          </div>
          <div class="user-name">
              {{ user.lastName }} {{ user.firstName }}
          </div>
          <div class="tag-name align-center light-text">@{{ user.primaryUserTagName }}</div>
  
          <div class="contact">
              <form>
                  <div>
                  <label>Name</label>
                  <div>
                    <div>
                      <input type="text" :value="user.firstName + ' ' + user.lastName">
                    </div>
                  </div>
                    </div>
                    <div>
                    <label>Resident email address</label>
                    <div>
                        <input type="email" v-model="user.businessEmailAddress">
                    </div>
                    </div>
                    <div>
                    <label>State</label>
                    <div>
                        <input type="text" v-model="user.state">
                    </div>
                    </div>
                    <div>
                    <label>Address</label>
                    <div>
                        <input type="text" v-model="user.address">
                    </div>
                    </div>
                    <div>
                    <label>City</label>
                    <div>
                        <input type="text" v-model="user.city">
                    </div>
                    </div>
                    <div>
                    <label>Country</label>
                    <div>
                        <input type="text" v-model="user.country">
                    </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="inputs btns">
            <div>
                {{ user.userId }}
                <Button @click.prevent="acceptRequest(user.userId)"  :style="{ color: '#fff', backgroundColor: '#159661', width: '106px', borderRadius: '10px', padding: '10px' }" text="Accept"/>
            </div>
            <div>
                <Button @click.prevent="declineRequest(user.userId)" :style="{ color: '#fff', backgroundColor: 'red', width: '100px', borderRadius: '10px', padding: '10px' }" text="Decline"/>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Button from '@/components/Button'
import axios from 'axios'
  
  export default {
      components: {
          Button
      },
      props: ['user'],
      data() {
          return {
              
          }
      },
      methods: {
        acceptRequest(id){
            axios.patch(`${this.$store.state.apiUrl}/api/Notification/accept-home-enrollment-request`, {
                secondaryCategoryId: this.user.secondaryCategoryId,
                userId: id
            })
            .then(res => console.log(res.data))
            .catch(err => console.log(err.response.data))
        },
        declineRequest(id){
            axios.patch(`${this.$store.state.apiUrl}/api/Notification/decline-home-enrollment-request`, {
                secondaryCategoryId: this.user.secondaryCategoryId,
                userId: id
            })
            .then(res => console.log(res.data))
            .catch(err => console.log(err.response.data))
        }
      }
  }
  </script>
  
  <style scoped>
      .appointment {
        padding: 30px;
        background: #fff;
        border-radius: 20px;
        margin-left: 15px;
        min-height: 780px;
        box-shadow: 0 0 6px 2px #d1d1d1;
      }
  
      .light-text {
          color: #8d8d8d;
          font-size: 13px;
          margin-bottom: 20px;
      }
  
      .btns {
          display: flex;
          justify-content: space-between;
          padding: 10px 50px;
      }
  
      .image-holder {
          width: 100px;
          height: 100px;
          border-radius: 100%;
          margin: 0 auto;
          background-color:#DDF3FF;
          background-size: cover;
          overflow: hidden;
          text-align: center;
      }
  
      .image-holder img {
          width: 100%;
      }
  
      .picture {
          position: relative;
          padding-top: 2rem;
      }
  
      .user-name {
          font-size: 14px;
          font-weight: 600;
          margin-top: 10px;
          text-transform: capitalize;
      }
  
      .inputs {
          margin-top: 20px;
      }
  
      .staff, .user-name, .text {
          text-align: center;
      }
  
      .text  {
          font-size: 13px;
          font-weight: 500;
          color: #979797;
      }
  
      /* form  */
  
      .contact {
          margin-top: 18px;
      }

      .contain {
        height: 680px;
        overflow: auto;
      }
  
      .contact h4 {
          font-size: 14px;
      }
  
      form > div {
        margin-bottom: 5px;
      }
  
      form > div:nth-child(3) {
          margin-bottom: 18px;
      }
  
      form label {
        font-size: 12px;
        color: #a7a7a7;
      }
  
      form input, form select {
        margin-top: 2px;
        border: 1px solid #a7a7a7;
        font-size: 13px;  
        pointer-events: none;
      }

      .inputs {
        margin-top: 25px;
        position: sticky;
        bottom: 0;
        background-color: #fff;
      }
  </style>