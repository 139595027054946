<template>
  <div class="entry">
      <div class="entry-box">
          <div class="scroll">
              <div class="no-entry flex-center" v-if="entries.length === 0">No entry found</div>
          <div class="entry-item" v-for="(entry, index) in entries" :key="index" @click="$emit('show-data', entry)">
              <div class="image-spot">
                <div class="image-holder">
                    <img :src="entry.img" alt="img">
                </div>
              </div>

              <div class="text">
                  <div class="name">{{ entry.name }}</div>
                  <div class="tag">@{{ entry.tagName }}</div>
              </div>

              <div class="right">
                  <div class="time">{{ entry.time }}</div>
                  <div class="dot" v-if="entry.status == 'clocked'"></div>
                  <div class="in green" v-if="entry.status == 'in'">In</div>
                  <div class="out red" v-if="entry.status == 'out'">Out</div>
              </div>
          </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: ['entries']
}
</script>

<style scoped>
    img {
        width: 100%;
    }

    .no-entry {
        font-size: 25px;
        color: #ccc;
        height: 300px;  
    }

    .scroll {
        width: 100%;
        /* background-color: #fff; */
        margin-top: 10px;
        height: 400px;
        overflow: auto;
        border-radius: 20px;
    }
    .scroll::-webkit-scrollbar {
        width: 0.2em;
    }
    
    /* .scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    } */
    
    .scroll::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    }

    .entry-box {
        padding: 0 10px 0 0;
        overflow: hidden;
        min-height: 500px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 0 6px 2px #e8e8e8;
    }

    .entry-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid #f3f3f3;
    }

    .image-spot {
        flex: 0.5;
        margin-right: 10px;
    }

    .entry-item .text {
        flex: 10;
    }

    .entry-item .right {
        flex: 1;
    }

    .image-holder {
        width: 50px;
        height: 50px;
        border-radius: 100px;
        overflow: hidden;
    }

    .dot {
        background-color: #fac800;
        height: 8px;
        width: 8px;
        border-radius: 100px;
        margin-top: 2px;
        float: right
    }

    .name {
        font-weight: 600;
        font-size: 14px;
    }

    .text {
        margin-left: 10px;
    }

    .tag, .time {
        color: #a7a7a7;
        font-size: 12px;
    }

    .time {
        text-align: right;
    }

    .right {
        text-align: right;
        font-size: 13px;
    }
    
</style>