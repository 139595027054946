<template>
  <div class="settings">
      <DashboardHeader page="Settings"/>
      <div class="header">
        <Header/>
      </div>
      <div class="bottom">
        <div class="entry">
          <Entry v-on:open-box="openBox"/>
        </div>
        <div class="action" v-if="actionPage !== 0">
          <DeleteCard v-if="actionPage === 1" v-on:open-box="openBox"/>
          <ToneCard v-if="actionPage === 2" v-on:open-box="openBox"/>
          <EntryCriteriaCard v-if="actionPage === 3" v-on:open-box="openBox"/>
          <ChangePasswordCard v-if="actionPage === 4" v-on:open-box="openBox"/>
        </div>
      </div>
  </div>
</template>

<script>
import DashboardHeader from '@/components/DashboardHeader.vue'
import Header from './components/Header.vue'
import Entry from './components/Entry.vue'
import DeleteCard from './components/Delete.vue'
import ToneCard from './components/Tone.vue'
import EntryCriteriaCard from './components/EntryCriteria.vue'
import ChangePasswordCard from './components/ChangePassword.vue'

export default {
  components: {
    DashboardHeader,
    Header,
    Entry,
    DeleteCard,
    ToneCard,
    EntryCriteriaCard,
    ChangePasswordCard
  },
  data() {
    return {
      actionPage: 0
    }
  },
  methods: {
    openBox(value){
      this.actionPage = value
    }
  }
}
</script>

<style scoped>
    .settings {
        padding: 0 10px;
    }

    .header {
      margin-top: 4rem;
    }

    .bottom {
        align-items: flex-start;
        display: flex;
        padding: 0 10px;
        margin-top: 30px;
        min-height: 600px;
    }

    .bottom .entry {
        flex: 4;
        margin-right: 5px;
    }

    .bottom .action {
        flex: 2.5;
        padding: 20px;
        background-color: #fff;
        border-radius: 20px;
        min-height: 550px;
    }
</style>