<template>
  <div class="tone">
      <h4 class="head">
          Beep Tone
      </h4>

      <div class="options">
          <div class="item" v-for="(tone, index) in tones" :key="index">
              <div class="text">
                  <span class="play-btn" @click="playTone(tone.sound)"></span> {{ tone.name }}
              </div>
              <div class="selected flex-center" v-if="tone.selected">
                  <i class="fa fa-check"></i>
              </div>
          </div>
      </div>

      <div class="bottom">
            <Button 
            @click="$emit('open-box', 0)"
            :style="{ color: '#000', backgroundColor: '#f3f3f3', width: '176px', borderRadius: '8px', padding: '10px', fontWeight: '700' }" 
            text="DISMISS"
          />
          </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
    components: {
        Button
    },
    data(){
        return {
            tones: [
                {
                    name: 'Beep tone 1',
                    sound: 'http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3',
                    selected: true
                },
                {
                    name: 'Beep tone 2',
                    sound: 'http://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3',
                    selected: false
                },
                {
                    name: 'Beep tone 3',
                    selected: false
                },
                {
                    name: 'Beep tone 4',
                    selected: false
                }
            ]
        }
    },
    methods: {
        playTone(sound) {
            if(sound) {
                const audio = new Audio(sound);
                audio.play();
            }
        }
    }
}
</script>

<style scoped>
    .tone  {
        padding: 10px;
    }

    .play-btn {
        margin-right: 10px;
    }

    .play-btn:hover {
        color: green;
    }

    .head {
        margin-bottom: 40px;
        text-align: center;
    }

    .options > div {
        padding: 10px;
        border-bottom: 1px solid #f3f3f3;
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
    }

    .options > div:hover {
        background-color: #f3f3f3;
    }
    
    .bottom {
        margin-top: 7rem;
        text-align: center;
    }

    .selected {
        height: 20px;
        width: 20px;
        background: green;
        color: #fff;
        border-radius: 100%;
    }
</style>