import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueSmoothScroll from 'vue3-smooth-scroll'
// importing AOS css style globally
import 'aos/dist/aos.css'

createApp(App).use(store).use(router).use(VueSmoothScroll, {
    duration: 1000,
    updateHistory: true
  }).mount('#app')