<template>
  <div class="notification">
      <DashboardHeader page="Notification"/>
      <div class="header">
          <Header/>
      </div>
      <div class="bottom">
          <div class="entry">
              <Entry v-on:show-data="showData"/>
          </div>
          <div class="request-card" v-if="showCard">
              <RequestCard :data="data" 
              v-if="cardType == 1 && showRequest == false" 
              :showRequest="showRequest"
              v-on:changeRequestToTrue="showRequest = true"
              />
              <Message :data="data" v-if="cardType == 0"/>
              <HomeDetails :user="data" v-if="showRequest" v-on:go-back="showRequest = false"/>
          </div>
      </div>
  </div>
</template>

<script>
import DashboardHeader from '@/components/DashboardHeader.vue'
import Header from './components/Header.vue'
import Entry from './components/Entry.vue'
import RequestCard from './components/RequestCard.vue'
import Message from './components/Message.vue'
import HomeDetails from './components/request_details/Home.vue'
import BusinessDetails from './components/request_details/Business.vue'
import SchoolDetails from './components/request_details/Home.vue'

export default {
  components: {
    DashboardHeader,
    Header,
    Entry,
    RequestCard,
    Message,
    HomeDetails,
    BusinessDetails,
    SchoolDetails
  },
  data() {
      return {
          notifications: [
            // { type: 0, image: '', sender: 'Linkmeup', tagName: "linkmeup", message: 'Always use your directory to accept request from others', date: 'Thursday', time: '3:45PM', viewed: false },
            // { type: 1, image: 'https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500', sender: 'Nonso Godfrey', tagName: "nonsogodfrey", message: 'Resident enrollment request', date: 'Thursday', time: '3:45PM', viewed: false },
            // { type: 1, image: 'https://images.unsplash.com/photo-1599566150163-29194dcaad36?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fHBlcnNvbnxlbnwwfHwwfHw%3D&w=1000&q=80', tagName: "slaviask", sender: 'Slaviask Kwulievesky', message: 'Resident enrollment request', date: 'Thursday', time: '3:45PM', viewed: false },
            // { type: 1, image: 'https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500', sender: 'Jamie Jackson', tagName: "jamiejack", message: 'Resident enrollment request', date: 'Thursday', time: '3:45PM', viewed: true },
            // { type: 0, image: '', sender: 'Linkmeup', tagName: "linkmeup" ,message: 'Always use your directory to accept request from others', date: 'Thursday', time: '3:45PM', viewed: true },
            // { type: 1, image: 'https://img.freepik.com/free-photo/cheerful-middle-aged-woman-with-curly-hair_1262-20859.jpg?w=2000', sender: 'Hillary Tragger', tagName: "hillarytragger", message: 'Resident enrollment request', date: 'Thursday', time: '3:45PM', viewed: true },
            // { type: 0, image: '', sender: 'Linkmeup', tagName: "linkmeup", message: 'Always use your directory to accept request from others', date: 'Thursday', time: '3:45PM', viewed: true }
        ],
        showCard: false,
        showRequest: false,
        cardType: 0,
        data: {}
      }
  },
  methods: {
    showData(data) {
        this.data = data.notification
        data.type == 0 ? this.cardType = 0 : this.cardType = 1
        this.showCard = true
    },
    dismissNotification() {
        this.showDetails = false
    }
  }
}
</script>

<style scoped>
    .notification {
        padding: 0 10px;
    }

    .header {
        margin-top: 4rem;
    }

    .bottom {
        align-items: flex-start;
        display: flex;
        padding: 0 10px;
        margin-top: 30px;
    }

    .bottom > div:first-child {
        flex: 3;
    }

    .bottom > div:last-child {
        flex: 1.8;
    }
</style>