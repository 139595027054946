<template>
  <div class="details">
    <div class="top">
      <h3>Business</h3>
      <h3>Premium plan features</h3>
      <h3 class="light-text">$150</h3>
    </div>

    <div class="plan-item">General  service</div>
    <div class="plan-item">
      Multi-user
      <div class="list">
        <ul>
          <li>Administrator</li>
          <li>Sub-dministrator</li>
          <li>Receptionist</li>
          <li>Security</li>
        </ul>
      </div>
    </div>

    <div class="plan-item">
      Clock in and clock out
      <div class="list">
        <ul>
          <li>Guest</li>
          <li>Staff</li>
        </ul>
      </div>
    </div>

    <div class="plan-item">
      My Order
      <div class="list">
        <ul>
          <li>Business cards</li>
        </ul>
      </div>
    </div>

    <div class="btn">
      <Button :style="{ color: '#fff', backgroundColor: '#159661', width: '176px', borderRadius: '5px', padding: '10px' }" text="Subscribe"/>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
  components: {
    Button
  }
}
</script>

<style scoped>
  .details {
    padding: 2rem 3rem;
    background-color: #fff;
    border-radius: 20px;
    min-height: 600px;
  }

  .light-text {
    color: #979797;
  }

  .top {
    text-align: center;
    margin-bottom: 10px;
  }

  .top > h3 {
    /* margin-bottom: 5px; */
    font-size: 15px;
  }

  .plan-item {
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .btn {
    text-align: center;
  }

  .list {
    padding: 0 25px;
    margin-top: 7px;
  }

</style>