<template>
  <div class="subscription">
      <DashboardHeader page="Subscription"/>
      <div class="header">
        <Header/>
      </div>
      <div class="bottom flex">
        <div class="entry">
          <!-- <Entry v-on:show-result="showResult" v-if="!displayResult"/> -->
          <Entry/>
          <!-- <Result v-if="displayResult" :plan="plan" v-on:close-result="displayResult = !displayResult"/> -->
        </div>
        <div class="details">
          <Details/>
        </div>
      </div>
  </div>
</template>

<script>
import DashboardHeader from '@/components/DashboardHeader.vue'
import Header from './components/Header.vue'
import Entry from './components/Entry.vue'
import Details from './components/Details.vue'
// import Result from './components/Result.vue'

export default {
  components: {
    DashboardHeader,
    Header,
    Entry,
    Details
    // Result
  },
  data() {
    return {
      displayResult: false,
      plan: ''
    }
  },
  methods: {
    showResult(plan) {
      this.displayResult = !this.displayResult
      this.plan = plan
    }
  }
}
</script>

<style scoped>
    .subscription {
        padding: 0 10px;
    }

    .header {
      margin-top: 4rem;
    }

    .entry{
      flex: 4;
      padding: 0 10px;
    }

    .details {
      flex: 2.8;
    }
</style>