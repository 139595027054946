<template>
  <div class="appointment">
    <br><br>
      <div class="picture">
        <div class="image-holder">
            <img :src="user.pictureUrl">
        </div>

        <div class="user-name">
            {{ user.firstName }} {{ user.lastName }}
        </div>
        <div class="text">@{{ user.primaryUserTagName }}</div>

        <div class="inputs">
            <div class="staff">
                <b>{{ user.role }}</b>
            </div>
        </div>

        <div class="contact">
            <div>
                <h4>Personal contact</h4>
            </div>
            <form>
                <div>
                <label>Phone number</label>
                <div>
                  <div>
                    <!-- <div>+234</div> -->
                    <input type="text" :value="user.personalPhoneNumber">
                  </div>
                </div>
              </div>
              <div>
                <label>Official email</label>
                <div>
                  <input type="email" :value="user.personalEmail">
                </div>
              </div>
              <div>
                <label>Personal website</label>
                <div>
                  <input type="email" :value="user.personalWebsite">
                </div>
              </div>
            </form>
        </div>
        
        <div class="contact">
            <div>
                <h4>Business contact</h4>
            </div>
            <form>
                <div>
                <label>Phone number</label>
                <div>
                  <div>
                    <!-- <div>+234</div> -->
                    <input type="text" :value="user.personalPhoneNumber">
                  </div>
                </div>
              </div>
              <div>
                <label>Direct line</label>
                <div>
                  <input type="email" :value="user.personalEmail">
                </div>
              </div>
              <div>
                <label>Email</label>
                <div>
                  <input type="email" :value="user.personalEmail">
                </div>
              </div>
              <div>
                <label>Designation</label>
                <div>
                  <input type="email" :value="user.personalWebsite">
                </div>
              </div>
              <div>
                <label>Department</label>
                <div>
                  <input type="email" :value="user.personalWebsite">
                </div>
              </div>
              <div>
                <label>Company name</label>
                <div>
                  <input type="email" :value="user.personalWebsite">
                </div>
              </div>
              <div>
                <label>Company name tag</label>
                <div>
                  <input type="email" :value="user.personalWebsite">
                </div>
              </div>
              <div>
                <label>Company website</label>
                <div>
                  <input type="email" :value="user.personalWebsite">
                </div>
              </div>
              <div>
                <label>Company address</label>
                <div>
                  <input type="email" :value="user.personalWebsite">
                </div>
              </div>
              {{ user }}
            </form>
        </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button'

export default {
    components: {
        Button
    },
    props: ['user'],
    data() {
        return {
            
        }
    }
}
</script>

<style scoped>
    .appointment {
        background: #fff;
        height: 600px;
        overflow: auto;
        border-radius: 20px;
        padding: 10px 0;
    }

    .light-text {
        color: #8d8d8d;
        font-size: 13px;
        margin-bottom: 20px;
    }

    .btns {
        display: flex;
        justify-content: space-between;
        padding: 10px 50px;
    }

    .image-holder {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        margin: 0 auto;
        background-color:#DDF3FF;
        background-size: cover;
        overflow: hidden;
        text-align: center;
    }

    .image-holder img {
        width: 100%;
    }

    .picture {
        position: relative;
        margin: 0 auto;
        margin-bottom: 40px;
        padding-top: 20px;
    }

    .user-name {
        font-size: 14px;
        font-weight: 600;
        margin-top: 10px;
    }

    .inputs {
        margin-top: 25px;
    }

    .staff, .user-name, .text {
        text-align: center;
    }

    .text  {
        font-size: 13px;
        font-weight: 500;
        color: #979797;
    }

    /* form  */

    .contact {
        padding: 0 30px;
        margin-top: 30px;
    }

    .contact h4 {
        font-size: 14px;
        font-weight: 500;
    }

    
  .fake-input {
        background: #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        width: 100%;
        padding: 0 10px;
        color: #000;
        border: 1px solid #a7a7a7;
    }

    .fake-input input {
      border: none;
      width: 100%;
      flex: 8;
      font-size: 13px;
    }

    .fake-input div {
      flex: 1;
    }

    form > div {
      margin-bottom: 5px;
    }

    form label {
      font-size: 12px;
      color: #a7a7a7;
    }

    form input, form select {
      margin-top: 2px;
      border: 1px solid #a7a7a7;
      font-size: 13px;  
      pointer-events: none;
    }
</style>