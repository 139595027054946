<template>
  <div class="qrcode-page" @click="closeModal">
    <div class="modal-content" id="modal-content">
      
        <div class="body">
          <datepicker :value="date" :open-date="date" @selected="dateSelected"></datepicker>
        </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs3-datepicker';

export default {
  components: {
    Datepicker
  },
  data(){
      return {
          date: new Date(),
          classes: ['qrcode-page', 'modal-content'],
      }
  },
  methods: {
      dateSelected(value){
          this.$emit('sort-entry-list', value)
      },
      closeModal(e) {
          if(this.classes.includes(e.target.className)) {
              this.$emit('sort-entry-list', this.date)
          }
      }
  },
}
</script>

<style scoped>
    .qrcode-page {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
      overflow: auto;
      width: 100%;
      background: rgba(0,0,0,0.3);
      padding: 20px 0px;
    }

    /* .modal-content {
      margin-top: 10rem;
    } */

    .btns {
        display: flex;
        justify-content: space-between;
        padding: 30px 0;
        width: 300px;
    }

    .body {
        flex-direction: column;
        width: 200px;
        margin: 0 auto;
    }

    
    .scroll::-webkit-scrollbar {
        width: 0.2em;
    }
    
    .scroll::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    outline: none;
    }
</style>