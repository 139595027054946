<template>
  <div class="qrcode-page flex-center" @click="closeModal">
    <div class="modal-content" :class="user.userRole ? user.userRole : 'null-class'">
      <div class="top">
        <div class="close-btn flex-center">&times;</div>
      </div>
      <div class="second-section flex-center">
        <h4>Linkmeup</h4>
        <div class="admin-text">{{ user.userRole ? user.userRole : 'Null' }}</div>
        <div class="qr-code-spot">
          <QrcodeVue :size="qrCode.size" :value="user.secondaryCategoryId" :level="qrCode.level"/>
        </div>
      </div>

      <!-- <div class="bottom flex">
        <Button title="Print QR code" :style="{ marginTop: '10px', color: '#fff', backgroundColor: '#159661', width: '130px', borderRadius: '5px', padding: '10px' }" text="Print"/>
        <Button title="Download QR code" :style="{ marginTop: '10px', color: '#159661', backgroundColor: 'transparent', width: '130px', borderRadius: '5px', padding: '10px', border: '1px solid #159661' }" text="Download"/>
      </div> -->
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import Button from '@/components/Button.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    QrcodeVue,
    Button
  },
  computed: {
    ...mapGetters([
      'user',
    ])
  },
  data(){
    return {
      qrCode: {
        size: 270,
        level: 'H'
      },
      classes: ['qrcode-page flex-center', 'close-btn flex-center', 'fa fa-times']
    }
  },
  methods: {
    closeModal(e){
      if(this.classes.includes(e.target.className)){
        this.$router.push('/dashboard')
      }
    }
  }
}
</script>

<style scoped>
    .qrcode-page {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
      overflow: auto;
      width: 100%;
      background: rgba(0,0,0,0.7);
      padding: 20px 0px;
    }

    .modal-content {
      height: 530px;
      width: 430px;
      color: #000;
      border-radius: 30px;
      padding: 20px;
      box-shadow: 0 0 6px 6px #00000035;
      /* margin-top: 10rem; */
    }

    .bottom {
      margin-top: 50px;
    }

    .top {
      text-align: right;
      display: flex;
      justify-content: right;
    }

    .close-btn {
      height: 20px;
      width: 20px;
      font-size: 15px;
      border-radius: 100px;
      text-align: center;
      cursor: pointer;
      text-align: right;
      background-color: rgba(0, 0, 0, 0.152);
    }

    .close-btn:hover {
      background-color: rgba(0, 0, 0, 0.042);
    }

    .second-section {
      flex-direction: column;
      height: 100%;
    }

    .second-section > div {
      margin-bottom: 15px
    }

    .qr-code-spot {
      border: 1px solid #ccc;
      padding: 22px;
      border-radius: 20px;
      background-color: #fff;
    }

    .admin-text, .small-text {
      font-weight: 600;
      text-align: center;
    }

    .admin-text {
      font-size: 14px;
    }

    .small-text {
      font-size: 13px;
      width: 300px;
    }

    .Administrator, .SubAdmin {
      background-color: #25A244;
      color: #fff;
    }
    .Receptionist {
      background-color: #0DBCF3;
    }
    .Security {
      background-color: #A6A6A8;
    }
    .security-in {
      background-color: #A6A6A8;
    }
    .security-out {
      background-color: #A6A6A8;
    }

    .null-class {
      background-color: #fff;
    }
</style>