import axios from 'axios'
import store from './index'

export default {
    state: {
        url: "http://linkmeupsecv2-001-site1.btempurl.com",
        secondaryUser: {},
        secondaryUsers: [],
        userRoles: []
    },
    getters: {
        getSecondaryUser(state) {
            return state.secondaryUser
        },
        secondaryUsers(state) {
            return state.secondaryUsers
        },
        userRoles(state) {
            return state.userRoles
        }
    },
    mutations: {
        getUsersData(state, data){
            state.secondaryUsers = data.users
        }
    },
    actions: {
        fetchSecondaryUsers({commit, state}) {
            axios.get(`${state.url}/api/User/get-users-with-roles?secondaryCategoryId=${store.state.auth.user.secondaryCategoryId}`)
            .then(res => {
                if(res.data.code == '200') {
                    commit('getUsersData', {
                        users: res.data.data
                    })
                } else {
                    alert("Error occured")
                }
            })
            .catch(err => console.log(err))
        },
        fetchUserRoles({commit, state}){
            axios.get(`${state.url}/api/User/get-roles`)
            .then(res => {
                state.userRoles = res.data.data
            })
            .catch(err => {
                console.log(err)
            })
        }
    }
}