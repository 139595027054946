<template>
  <div class="entry">
      <div class="entry-box">
          <div class="scroll">
          <div class="entry-item">
              <div class="image-spot">
                Notification
              </div>

              <div class="right">
                  <div class="checkbox">
                      <!-- Rounded switch -->
                    <label class="switch">
                    <input type="checkbox">
                    <span class="slider round"></span>
                    </label>
                  </div>
              </div>
          </div>
          <div class="entry-item" @click="$emit('open-box', 1)">
              <div class="image-spot">
                Delete account
              </div>

              <div class="right">
                  
              </div>
          </div>
          <!-- <div class="entry-item">
              <div class="image-spot">
                Vibrate
              </div>

              <div class="right">
                  <div class="checkbox">
                    <div class="checkbox">
                        Rounded switch
                        <label class="switch">
                        <input type="checkbox">
                        <span class="slider round"></span>
                        </label>
                    </div>
                  </div>
              </div>
          </div> -->
          <div class="entry-item" @click="$emit('open-box', 2)">
              <div class="image-spot">
                Beep
              </div>

              <div class="right">
                  <div class="checkbox">
                      <small class="text">Beep tone 1</small>
                  </div>
              </div>
          </div>
          <div class="entry-item" @click="$emit('open-box', 3)">
              <div class="image-spot">
                Entry criteria
              </div>

              <div class="right">
                  
              </div>
          </div>
          <div class="entry-item" @click="$emit('open-box', 4)">
              <div class="image-spot">
                Change password
              </div>

              <div class="right">
                  
              </div>
          </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            value: true
        }
    }
}
</script>

<style scoped>
    img {
        width: 100%;
    }

    .scroll {
        width: 100%;
        background-color: #fff;
        margin-top: 10px;
        max-height: 60vh;
        overflow: auto;
    }
    .scroll::-webkit-scrollbar {
        width: 0.2em;
    }
    
    /* .scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    } */
    
    .scroll::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    }

    .entry-box {
        padding: 10px 20px 20px 20px;
        overflow: hidden;
        min-height: 550px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 0 6px 2px #e8e8e8;
        overflow: auto;
    }

    .entry-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid #f7f6f6;
    }

    .image-spot {
        font-weight: 500;
        font-size: 14px;
    }

    .checkbox .text {
        font-size: 12px;
        color: #434343;
    }

    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 29px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        box-shadow: 0 1px 0px 1px #8f8f8f;
    }

    input:checked + .slider {
    background-color: #00b300;
    }

    input:focus + .slider {
    box-shadow: 0 0 1px #00b300;
    }

    input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
    border-radius: 34px;
    }

    .slider.round:before {
    border-radius: 50%;
    }
    
</style>