<template>
  <div class="profile">
    <div class="main-header">
      <dashboard-header page="Directory"></dashboard-header>
    </div>
      <div class="header">
        <Header/>
      </div>
      <div class="bottom">
        <div class="entry">
            <Entry v-on:show-data="showData"/>
        </div>
        <div class="appointment" v-if="showDetails">
            <Details :user="user" v-on:dismiss="dismissUser"/>
        </div>
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import DashboardHeader from '@/components/DashboardHeader.vue'
import Entry from './components/Entry.vue'
import Details from './components/Details.vue'

export default {
  components: {
    Header,
    DashboardHeader,
    Entry,
    Details
  },
  created() {
      this.$store.dispatch('fetchDirectoryUsers')
  },
  data() {
    return {
      showDetails: false,
      user: {}
    }
  },
  methods: {
      showData(user) {
          this.user = user
          this.showDetails = true
      },
      dismissUser(tagName) {
          this.entries = this.entries.filter(user => user.tagName !== tagName)
          this.showDetails = false
          console.log(tagName)
      }
  }
}
</script>

<style scoped>
  .profile {
    padding: 0 10px;
  }

  .header {
    margin-top: 4rem;
  }

  .entry {
    padding: 0 10px;
  }

  .bottom {
    display: flex;
  }

  .bottom > div:first-child {
    flex: 4;
  }

  .appointment {
      flex: 2.3;
      margin-right: 10px;
  }
</style>