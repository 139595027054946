<template>
  <div class="entry">
      <div class="entry-box">
          <div class="tabs flex">
              <div :style="currentTab == 0 ? 'border-bottom: 3px solid #000;' : null" @click="currentTab = 0">All</div>
              <div :style="currentTab == 1 ? 'border-bottom: 3px solid #000;' : null" @click="currentTab = 1">Guests</div>
              <div :style="currentTab == 2 ? 'border-bottom: 3px solid #000;' : null" @click="currentTab = 2">Staff</div>
          </div>
          <div class="scroll">
          <div class="entry-item" v-for="(user, index) in users" :key="index" @click="$emit('show-data', user)">
              <div class="image-spot">
                <div class="image-holder">
                    <img :src="user.img">
                </div>
              </div>

              <div class="text">
                  <div class="name">{{ user.name }}</div>
                  <div class="tag">@{{ user.tagName }}</div>
              </div>

              <!-- <div class="right">
                  <div class="time">2:24pm</div>
                  <div class="entry-count">10</div>
              </div> -->
          </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: ['users'],
    data() {
        return {
            currentTab: 0
        }
    }
}
</script>

<style scoped>
    img {
        width: 100%;    
    }

    .scroll {
        width: 100%;
        background-color: #fff;
        height: 450px;
        overflow: auto;
        border-radius: 20px;
    }
    .scroll::-webkit-scrollbar {
        width: 0.2em;
    }

    /* .scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    } */
    
    .scroll::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }

    .entry-box {
        /* padding: 20px 20px 20px 0; */
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 0 6px 2px #e8e8e8;
        height: 500px;
        overflow: hidden;
    }

    .entry-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid #f3f3f3;
    }

    .image-spot {
        flex: 0.5;
        margin-right: 10px;
    }

    .entry-item .text {
        flex: 10;
    }

    /* .entry-item .right {
        flex: 1.2;
    } */

    .image-holder {
        width: 50px;
        height: 50px;
        border-radius: 100px;
        overflow: hidden;
    }

    .dot {
        background-color: #159661;
        height: 8px;
        width: 8px;
        border-radius: 100px;
        margin-top: 2px;
        float: right
    }

    .name {
        font-weight: 600;
        font-size: 14px;
    }

    .tag, .time {
        color: #a7a7a7;
        font-size: 12px;
    }

    .right {
        text-align: right;
    }
    
    .entry-count {
        /* text-align: right; */
        color: #242424;
        font-size: 12px;
    }

    /* tabs  */
    .tabs {
        font-size: 14px;
        font-weight: 600;
        border-bottom: 1px solid #ccc;
    }

    .tabs > div {
        padding: 15px;
        width: 100%;
        text-align: center;
        cursor: pointer;
    }

    .tabs > div:hover {
        background-color: #f3f3f3;
        transition: background 0.3s ease-out;
    }
</style>