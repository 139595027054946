import { createStore } from "vuex";
import auth from './auth'
import users from './users'
import directory from "./directory";
import notifications from "./notifications";

export default createStore({
  state: {
    apiUrl: 'http://linkmeupsecv2-001-site1.btempurl.com'
  },
  modules: {
    auth,
    users,
    directory,    
    notifications
  },
});
