<template>
  <div class="message-card">
      <div class="top flex">
          <div class="image-holder">
              <img :src="data.image" v-if="data.image">
          </div>
          <div class="details">
              <div class="user-name">{{ data.firstName }}</div>
              <div class="text">@{{ data.tagName }}</div>
          </div>
      </div>

      <div class="messages">
          <div class="msg">
              <div class="msg-text">{{ data.message }}</div>
              <div class="time green">{{ data.date }}, {{ data.time }}</div>
          </div>
      </div>
  </div>
</template>

<script>
import Button from '@/components/Button'

export default {
    components: {
        Button
    },
    props: ['data'],
    data(){
        return {
            img: "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        }
    },
    methods: {
        
    }
}
</script>

<style scoped>
    .message-card {
        padding: 20px;
        background: #fff;
        border-radius: 20px;
        margin-left: 15px;
        min-height: 500px;
        box-shadow: 0 0 6px 2px #d1d1d1;
    }

    .image-holder {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background-color:#DDF3FF;
        background-size: cover;
        overflow: hidden;
        text-align: center;
        margin-right: 10px;
    }

    .image-holder img {
        width: 100%;
    }

    .user-name {
        font-size: 14px;
        font-weight: 600;
    }

    .top {
        justify-content: flex-start;
        border-bottom: 1px solid #ccc;
        padding: 10px 20px;
        margin-bottom: 15px;
    }

    .text {
        color: #494949;
        font-size: 13px;
    }

    .msg {
        font-size: 13px;
        margin-bottom: 15px;
        width: 180px;
        font-weight: 500;
    }

    .msg-text, .time {
        padding: 10px 10px;
    }

    .msg-text {
        background-color: #f3f3f3;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 20px;
    }
</style>