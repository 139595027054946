<template>
  <!-- <metainfo> -->
    <div id="app">
      <router-view />
    </div>
  <!-- </metainfo> -->
</template>

<script>
import AOS from "aos";

export default {
  mounted(){
    this.$store.dispatch('autoLogin')
    this.$store.dispatch('fetchUser')
    this.$store.dispatch('fetchUserRoles')
  },
  created(){
    AOS.init();
  },    
  watch: {
    $route(to) {
       const link = document.querySelector("[rel='icon']")
      if(!this.$store.state.auth.token) {
        document.title = `Linkmeup ${to.meta.title ? to.meta.title : ''}`;
        link.setAttribute('href', to.meta.icon)
      }

      if(to.meta.title == '| Security'){
        document.querySelector('meta[name="theme-color"]').setAttribute("content", "#159661");
      }

      if (to.meta.title == '| Everyday made better!') {
        document.querySelector('meta[name="theme-color"]').setAttribute("content", "#093AC9");
      }

      if(this.$store.state.auth.token) {
        setTimeout(() => {
        document.title = `Welcome ${to.meta.title ? to.meta.title : this.$store.state.auth.user.firstName}`;
        link.setAttribute('href', '/favicon2.ico')
      }, 1000)
      }
      
      this.$store.state.auth.loginError = ""
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box
}

body {
  overflow-x: hidden;
}

#app {
  font-family: 'poppins', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  overflow-x: hidden;
  /* color: #252B42; */
}

.home h1,
.home h2,
.home h3,
.home h4,
.home h5,
.home h6 {
  font-family: 'Lato', sans-serif;
}

form label {
    font-weight: bold;
    font-size: 15px;
  }

  form input, form select {
    padding: 10px;
    width: 100%;
    font-family: inherit;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
  }

  form input:focus {
    border-bottom: 1px solid #159661;
  }

  .green {
    color: #159661;
  }

  .red {
    color: red
  }

  .container {
    width: 80%;
    margin: 0 auto;
  }

  .flex { 
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .entry-item:hover {
    background: #f3f3f3;
    cursor: pointer;
  }

  .scroll {
    border-radius: 0 !important;
  }

  .align-center {
    text-align: center;
  }

  .flex-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button {
  border: none;
  font-family: inherit;
  cursor: pointer;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-msg {
  color: red;
  margin-top: 15px;
  font-size: 14px;
}

.success-msg {
  color: green;
  margin-top: 15px;
  font-size: 14px;
}

  #router {
        animation-name: modalopen;
        animation-duration: 0.3s;
    }

    .details-scroll::-webkit-scrollbar, .scroll::-webkit-scrollbar {
        width: 0.2em;
    }
    
    .details-scroll::-webkit-scrollbar-thumb,.scroll::-webkit-scrollbar-thumb {
      background-color: #d3d3d3;
      outline: none;
    }

    @keyframes modalopen {
      from {
          opacity: 0;
      }
      to {
          opacity: 1;
      }
  }

  @media(max-width: 1024px) {
    .container {
      width: 97%;
    }
  }
</style>
