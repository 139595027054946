<template>
  <div class="appointment">
      <div class="picture">
        <div class="image-holder">
            <img :src="user.pictureUrl">
        </div>
        <div class="user-name">
            {{ user.lastName }} {{ user.firstName }}
        </div>

        <div class="contact">
            <form>
                <div>
                <label>Name</label>
                <div>
                  <div>
                    <input type="text" :value="user.firstName + ' ' + user.lastName">
                  </div>
                </div>
              </div>
              <div>
                <label>email</label>
                <div>
                  <input type="email" v-model="user.email">
                </div>
              </div>
              <div>
                <label>Position</label>
                <div>
                  <input type="text" v-model="user.role">
                </div>
              </div>
              <div class="flex-center">
                  <Button @click.prevent="" :style="{ color: '#159661', backgroundColor: '#ffffff', width: '176px', borderRadius: '5px', padding: '10px' }" text="View entry list"/>
              </div>
              <div class="flex-center">
                  <Button @click.prevent="editUser" :style="{ color: '#fff', backgroundColor: '#159661', width: '176px', borderRadius: '30px', padding: '10px' }" text="Edit"/>
              </div>
            </form>
        </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button'

export default {
    components: {
        Button
    },
    props: ['user'],
    data() {
        return {
            
        }
    },
    methods: {
      editUser() {
        this.$store.state.users.secondaryUser = this.user
        this.$router.push('/users/edit')
      }
    }
}
</script>

<style scoped>
    .appointment {
        background: #fff;
        min-height: 600px;
        border-radius: 20px;
    }

    .light-text {
        color: #8d8d8d;
        font-size: 13px;
        margin-bottom: 20px;
    }

    .btns {
        display: flex;
        justify-content: space-between;
        padding: 10px 50px;
    }

    .image-holder {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        margin: 0 auto;
        background-color:#DDF3FF;
        background-size: cover;
        overflow: hidden;
        text-align: center;
    }

    .image-holder img {
        width: 100%;
    }

    .picture {
        position: relative;
        padding-top: 4rem;
    }

    .user-name {
        font-size: 14px;
        font-weight: 600;
        margin-top: 10px;
        text-transform: capitalize;
    }

    .inputs {
        margin-top: 25px;
    }

    .staff, .user-name, .text {
        text-align: center;
    }

    .text  {
        font-size: 13px;
        font-weight: 500;
        color: #979797;
    }

    /* form  */

    .contact {
        padding: 0 30px;
        margin-top: 30px;
    }

    .contact h4 {
        font-size: 14px;
    }

    form > div {
      margin-bottom: 5px;
    }

    form > div:nth-child(3) {
        margin-bottom: 20px;
    }

    form label {
      font-size: 12px;
      color: #a7a7a7;
    }

    form input, form select {
      margin-top: 2px;
      border: 1px solid #a7a7a7;
      font-size: 13px;  
      pointer-events: none;
    }
</style>