<template>
  <div class="qrcode-page flex-center" @click="closeModal">
    <div class="modal-content" id="modal-content">
      <div class="top"></div>
      <div class="flex-center">
          <div class="action">
            <div><b>Please enter your pin</b></div>
            <div class="pin-input">
                <input type="password" v-model="formData.pin" name="pin" id="profile-pin">
            </div>
            <div class="btns">
                <div class="spinner" v-if="loading">
                  <img src="https://www.thegibsonhotel.ie/wp-content/themes/gibson2017/images/loading.gif" alt="loading..." width="30">
                </div>
                <Button v-else @click.prevent="verifyPin" :style="{ color: '#fff', backgroundColor: '#159661', width: '100px', borderRadius: '3px', padding: '10px' }" text="Verify"/>  
                <Button @click.prevent="$emit('close-pin')" :style="{ color: '#fff', backgroundColor: '#bb0808', width: '100px', borderRadius: '3px', padding: '10px' }" text="Cancel"/>
            </div>
          </div>
      </div>
    </div>

    <div class="message flex" v-if="message">
      <span :class="message.type == 1 ? 'text-success' : 'text-error'">{{ message.text }}</span>
      <span 
      @click="message = ''"
      class="flex-center" 
      :class="message.type == 1 ? 'cancel-btn-success' : 'cancel-btn-error'"
      ><i class="fa fa-times"></i>
      </span>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import axios from 'axios'

export default {
  components: {
    Button
  },
  data(){
    return {
      classes: ['qrcode-page flex-center', 'close-btn flex-center', 'fa fa-times'],
      formData: {
        pin: ""
      },
      message: "",
      loading: false
    }
  },
  methods: {
    closeModal(e){
      if(this.classes.includes(e.target.className)){
        // this.$router.push('/users')
        this.$emit('close-pin')
      }
    },  
    verifyPin(){
      this.loading = true
      if(this.formData.pin.length < 6) {
        this.message = { type: 0, text: "Please enter 6 digit pin"}
      } else {
        axios.post(`${this.$store.state.auth.apiUrl}/api/SecondaryCategory/verify-pin-to-edit-secondary-category`,{
          pin: this.formData.pin,
          userId: this.$store.state.auth.user.id
        })
        .then(res => {
          if(res.data.code == 200) {
            this.message = { text: res.data.ShortDescription, type: 1 }
            this.$emit('close-pin')
            this.$store.state.auth.editSecondaryProfile = true
            this.loading = false
            this.$router.push('/profile/edit')
          }
        })
        .catch(err => {
          this.loading = false
          this.message = { 
            text: err.response ? err.response.data.ShortDescription : err, type: 0 }
        })
      }
    }
  }
}
</script>

<style scoped>
    .qrcode-page {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
      overflow: auto;
      width: 100%;
      background: rgba(0,0,0,0.7);
      padding: 10px 0px;
    }

    .modal-content {
      height: 40%;
      width: 400px;
      background: #fff;
      color: #262626;
      border-radius: 10px;
      /* padding: 20px; */
      overflow: hidden;
      box-shadow: 0 0 6px 4px #00000017;
      /* margin-top: 10rem; */
    }

    .top {
        height: 20%;
        background-color: #159661;
    }

    .action {
        padding: 30px 0;
        text-align: center;
    }

    .action input {
        padding: 15px;
        width: 150px;
        border: 1px solid #ccc;
        border-radius: 3px;
    }

    .btns {
        width: 220px;
        display: flex;
        justify-content: space-between;
    }

    .btns .spinner {
      /* border: 2px solid red; */
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100px;
      height:30px
    }

    .action > div {
        margin-bottom: 15px;
    }

    .message {
      position: absolute;
      bottom: 10px;
      left: 10px;
      color: #fff;
      align-items: stretch;
      width: 300px;
    }

    .message .text-success {
      flex: 7;
      padding: 10px;
      background-color: green;
      font-size: 14px;
    }

    .cancel-btn-success {
      flex: 1;
      background-color: #003300;
      cursor: pointer;
    }

    .cancel-btn-success:hover {
      background-color: #014b01;
    }

    .message .text-error {
      flex: 7;
      padding: 20px 10px;
      background-color: #FF3333;
      font-size: 14px;
    }

    .cancel-btn-error {
      flex: 1;
      background-color: #7f1919;
      cursor: pointer;
    }

    .cancel-btn-error:hover {
      background-color: #cc2828;
    }
</style>