<template>
    <div class="search">
        <div class="flex">
          <div class="date-picker" v-if="showDatePicker">
              <DatePicker v-on:sort-entry-list="sortList"/>
          </div>

          <div class="fake-input">
              <input type="text" placeholder="Search">
              <i class="fa fa-search"></i>
          </div>
        </div>

        <div class="right flex">
            <div @click="showDatePicker = true">
                <span class="date">{{ date }}</span> <i class="fa fa-calendar" aria-hidden="true"></i>
            </div>
            <div>
                <!-- <JsonCSV 
                :data="entries"
                name="LMU_Entry_history.csv"> -->
                    <i class="fa fa-external-link" style="margin-left: 20px; cursor: pointer;" ></i>
                <!-- </JsonCSV> -->
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from './Date.vue'
// import JsonCSV from 'vue-json-csv'

export default {
    props: ['entries'],
    components: {
        DatePicker,
        // JsonCSV
    },
    data(){
        return {
            date: new Date().toLocaleDateString(),
            showDatePicker: false
        }
    },
    methods: {
        sortList(date){
            this.date = new Date(date).toLocaleDateString()
            this.showDatePicker = false
        },
    }
}
</script>

<style scoped>
    .search {
        display: flex;
        width: 95%;
        margin: 2% auto;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .fa-external-link {
        cursor: pointer;
    }

    .fake-input {
        background: #fff;
        /* padding: 10px; */
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        width: 350px;
        padding: 0 10px;
        color: #ccc;
        margin-left: 20px;
    }

    input {
        padding: 10px;
        font-size: 15px;
        border: none;
        font-family: inherit;
        outline: none;
    }

    .fa-bell {
        font-size: 20px;
    }

    .date {
        font-size: 12px;
        margin-right: 10px;
        font-weight: 500;
    }

    .date, .fa-calendar {
        cursor: pointer;
    }
</style>