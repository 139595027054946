<template>
    <div class="stats">
        <div class="stat-item" @click="$emit('sort-list', 'all')">
            <div class="icon green">
                <i class="fa fa-refresh"></i>
            </div>

            <div class="entry">
                <p class="text">255</p>
                <p class="light-text">Entries</p>
            </div>
        </div>

        <div class="stat-item" @click="$emit('sort-list', 'clocked')">
            <div class="icon yellow">
                <i class="fa fa-envelope"></i>
            </div>

            <div class="entry">
                <p class="text">255</p>
                <p class="light-text">Clocked</p>
            </div>
        </div>

        <div class="stat-item" @click="$emit('sort-list', 'in')">
            <div class="icon green">
                <i class="fa fa-arrow-right"></i>
            </div>

            <div class="entry">
                <p class="text">300</p>
                <p class="light-text">In</p>
            </div>
        </div>

        <div class="stat-item" @click="$emit('sort-list', 'out')">
            <div class="icon red">
                <i class="fa fa-arrow-left"></i>
            </div>

            <div class="entry">
                <p class="text">25</p>
                <p class="light-text">Out</p>
            </div>
        </div>

        <div class="stat-item" @click="$emit('sort-list', 'dismissed')">
            <div class="icon grey">
                <i class="fa fa-times"></i>
            </div>

            <div class="entry">
                <p class="text">300</p>
                <p class="light-text">Dismissed</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .stats{
        display: flex;
        justify-content: space-between;
        /* margin: 20px 0; */
        width: 95%;
        margin: 0 auto;
    }

    .stat-item {
        background-color: #fff;
        padding: 30px 0;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        border-radius: 12px;
        width: 18%;
        box-shadow: 0 0 6px 6px #e8e8e8;
        cursor: pointer;
    }

    .stat-item:hover {
        background-color: #e6fff4;
    }

    .icon {
        /* padding: 10px; */
        border-radius: 100px;
        margin-right: 10px;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .stat-item .text {
        font-weight: bold;
        font-size: 20px;
    }

    .stat-item .light-text {
        color: #ccc;
        font-size: 15px;
        font-weight: 200;
    }

    .green {
        background: #15966046;
        color: #159661;
    }

    .yellow {
        color: #FFCB00;
        background-color: #ffcc004f;
    }

    .red {
        color: #E16148;
        background-color: #e1614846;
    }
    
    .grey {
        color: #434343;
        background-color: #bababa;
    }
</style>