import axios from 'axios'
import store from './index'

export default {
    state: {
        url: "http://linkmeupsecv2-001-site1.btempurl.com",
        notifications: []
    },
    getters: {
        notifications(state) {
            return state.notifications.Data
        }
    },
    mutations: {
        getNotifications(state, data){
            state.notifications = data.notifications
        }
    },
    actions: {
        fetchNotifications({commit, state}) {
            axios.get(`${state.url}/api/Notification/get-pending-enrollment-requests/${store.state.auth.user.secondaryCategoryId}`)
            .then(res => {
                commit('getNotifications', {
                    notifications: res.data.data
                })
            })
            .catch(err => console.log(err.response.data))
        }
    }
}