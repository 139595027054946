<template>
  <div class="change-password">
      <div class="head">
          Change Password
      </div>

      <div class="form">
          <form>
              <div>
                  <label>Old password</label>
                  <div>
                      <input type="password" id="old-password" placeholder="Enter old password">
                  </div>
              </div>
              <div>
                  <label>New password</label>
                  <div>
                      <input type="password" id="new-password" placeholder="Enter new password">
                  </div>
              </div>
              <div>
                  <label>Confirm new password</label>
                  <div>
                      <input type="password" id="confirm-password" placeholder="Re-type new password">
                  </div>
              </div>
              <div class="btn">
                  <Button @click.prevent="" :style="{ marginTop: '10px', color: '#fff', backgroundColor: '#159661', width: '178px', borderRadius: '30px', padding: '10px' }" text="Next"/>
              </div>
          </form>
      </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
    components: {
        Button
    }
}
</script>

<style scoped>
    .change-password {
        padding: 10px;
    }

    .head {
        margin-bottom: 30px;
        font-weight: 700;
        text-align: center;
    }

    form > div {
        margin-bottom: 25px;
    }

    form input {
        background-color: #f3f3f3;
        border: none;
        padding: 15px;
        border-radius: 20px;
        margin-top: 3px;
    }

    form input:focus {
        background-color: #15966044;
    }

    form label {
        font-weight: 600;
        font-size: 14px;
    }

    .btn {
        text-align: center;
    }
</style>