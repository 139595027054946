<template>
  <div class="users">
    <div class="main-header">
      <dashboard-header page="Users"></dashboard-header>
    </div>
    <div>
      <router-view id="router" :user="user"></router-view>
    </div>
      <div class="header">
        <Header/>
      </div>
      <div class="bottom flex">
        <div class="entry">
          <Entry v-on:show-data="showData"/>
        </div>
        <div class="add-form" v-if="showDetails">
          <Details :user="user"/>
        </div>
        </div>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import DashboardHeader from '@/components/DashboardHeader.vue'
import Entry from './components/Entry.vue'
import Details from './components/Details.vue'

export default {
  components: {
    Header,
    Entry,
    DashboardHeader,
    Details
  },
  data(){
    return {
      showDetails: false,
      user: {}
    }
  },
  methods: {
    showData(user) {
          this.user = user
          this.showDetails = true
      },
  }
}
</script>

<style scoped>
  .users {
    padding: 0 10px;
    height: 100vh;
    overflow: auto;
  }

  .header {
      margin-top: 4rem;
  }

  .flex {
    align-items: flex-start;
    padding: 0 10px;
    margin-top: 30px;
    min-height: 600px;
  }

  .flex > div:first-child {
    flex: 2;
  }

  .flex > div:last-child {
    flex: 1;
    background: #fff;
    height: 580px;
    border-radius: 20px;
    margin-left: 15px;
  }
</style>