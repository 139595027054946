<template>
  <div class="delete">
      <h4 class="head">
          Delete account
      </h4>

      <div class="options">
          <ul>
              <li>Delete account from Linkmeup</li>
              <li>Erase all entry history</li>
              <li>Delete all contacts on directory</li>
              <li>Delete user's using this account</li>
          </ul>
      </div>

      <div class="bottom">
          <Button 
            :style="{ color: '#fff', backgroundColor: '#B31B1B', width: '176px', borderRadius: '8px', padding: '10px', fontWeight: '700', marginBottom: '10px' }" 
            text="DELETE ACCOUNT"
          />
          <div>
            <Button 
            @click="$emit('open-box', 0)"
            :style="{ color: '#000', backgroundColor: '#f3f3f3', width: '176px', borderRadius: '8px', padding: '10px', fontWeight: '700' }" 
            text="DISMISS"
          />
          </div>
      </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
    components: {
        Button
    }
}
</script>

<style scoped>
    .delete {
        text-align: center;
    }

    .delete h4 {
        margin-bottom: 40px;
    }

    .delete .options {
        padding: 0 3rem;
        font-size: 14px;
        text-align: left;
        margin-bottom: 10rem;
        font-weight: 500;
    }
</style>