<template>
  <div class="appointment">
      <div class="picture">
        <div class="image-holder">
            <img :src="user.img">
        </div>

        <div class="user-name">
            {{ user.name }}
        </div>
        <div class="text">@{{ user.tagName }}</div>

        <div class="inputs">
            <div class="staff">
                <b>{{ user.role }}</b>
            </div>
            <div class="checkmark">
                <i class="fa fa-check"></i>
            </div>
        </div>

        <div class="btn-spot" v-if="user.status === 'clocked'">
          <Button @click="$router.push('/dashboard/appointment')" :style="{ border: '1px solid #159661', color: '#159661', backgroundColor: 'transparent', width: '200px', borderRadius: '50px', padding: '10px' }" text="View Appointment"/>
        </div>
    
        <div class="inputs btns">
            <div>
                <Button v-if="user.status === 'pending'" :style="{ color: '#fff', backgroundColor: '#159661', width: '106px', borderRadius: '50px', padding: '10px' }" text="Accept"/>
                <Button v-if="user.status !== 'pending'" :style="{ color: '#fff', backgroundColor: '#159661', width: '106px', borderRadius: '50px', padding: '10px' }" text="In"/>
            </div>
            <div>
                <Button v-if="user.status ==='pending' " :style="{ color: '#fff', backgroundColor: 'red', width: '100px', borderRadius: '50px', padding: '10px' }" text="Decline"/>
                <Button v-if="user.status !== 'pending' " :style="{ color: '#fff', backgroundColor: 'red', width: '100px', borderRadius: '50px', padding: '10px' }" text="Out"/>
            </div>
        </div>
      <div class="btn-spot">
          <Button @click="$emit('dismiss', user.tagName)" :style="{ color: '#000', backgroundColor: 'transparent', width: '200px', borderRadius: '50px', padding: '10px' }" text="Dismiss"/>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button'

export default {
    components: {
        Button
    },
    props: ['user'],
    data() {
        return {
            
        }
    }
}
</script>

<style scoped>
    .appointment {
        font-weight: bold;
        background: #fff;
        min-height: 500px;
        border-radius: 20px;
    }

    .light-text {
        color: #8d8d8d;
        font-size: 13px;
        margin-bottom: 20px;
    }

    .btns {
        display: flex;
        justify-content: space-between;
        padding: 10px 50px;
    }

    .btns > div:first-child {
        margin-right: 10px;
    }

    .image-holder {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        margin: 0 auto;
        background-color:#DDF3FF;
        background-size: cover;
        overflow: hidden;
        text-align: center;
    }

    .image-holder img {
        width: 100%;
    }

    .picture {
        position: relative;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 40px;
        padding-top: 20px;
    }

    .user-name {
        font-size: 14px;
        font-weight: 600;
        margin-top: 10px;
    }

    .inputs {
        margin-top: 25px;
    }

    .checkmark {
        border: 1px solid #159661;
        color: #159661;
        font-size: 23px;
        height: 60px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        margin: 15px auto;
    }

    .text  {
        font-size: 13px;
        font-weight: 500;
        color: #979797;
    }
</style>