<template>
  <div class="profile">
    <div class="main-header">
      <dashboard-header page="Profile"></dashboard-header>
    </div>
      <div class="header">
        <Header/>
      </div>

      <Pin v-if="showPin" v-on:close-pin="showPin = false"/>

      <div>
        <router-view id="router"></router-view>
      </div>
      <div class="flex">
        <div class="profile-display">
          <div class="item">
            <div class="image-holder">
              <img :src="user.secondaryCategoryPictureUrl" alt="">
            </div>

            <div class="text">
              <div class="name">{{ user.secondaryCategoryName }}</div>
              <div class="tag">@{{ user.secondaryCategoryTagName }}</div>
            </div>

            <div class="button">
              <Button @click="$router.push('/dashboard/qrcode')" :style="{ color: '#fff', backgroundColor: '#159661', width: '176px', borderRadius: '50px', padding: '10px' }" text="Show QR code"/>  
            </div>          
          </div>
        </div>

        <div class="profile-edit">
          <div class="head">
            <h4>Details</h4>
          </div>

          <form>
            <div>
              <label>Category</label>
              <div>
                <input type="text" :value="user.secondaryCategoryType">
              </div>
            </div>
              <div>
                <label>Phone number</label>
                <div>
                  <div class="fake-input">
                    <div>+234</div>
                    <input type="text" :value="user.secondaryCategoryPhoneNumber">
                  </div>
                </div>
              </div>
              <div>
                <label>Email</label>
                <div>
                  <input type="email" :value="user.secondaryCategoryEmailAddress">
                </div>
              </div>
              <div>
                <label>official Website</label>
                <div>
                  <input type="text" :value="user.secondaryCategoryWebsite">
                </div>
              </div>
              <div>
                <label>Address</label>
                <div>
                  <input type="text" :value="user.secondaryCategoryAddress">
                </div>
              </div>
              <div>
                <Button @click.prevent="showPin = true" :style="{ color: '#fff', backgroundColor: '#159661', width: '176px', borderRadius: '50px', padding: '10px' }" text="Edit"/>  
              </div>
          </form>
        </div>
      </div>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Button from '../../components/Button.vue'
import DashboardHeader from '@/components/DashboardHeader.vue'
import Pin from '@/components/Pin.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Header,
    Button,
    DashboardHeader,
    Pin
  },
  // mounted(){
  //   this.$store.dispatch('fetchUser')
  // },
  computed: {
      ...mapGetters([
          'user',
      ])
  },
  data() {
    return {
      showPin: false
    }
  }
}
</script>

<style scoped>
  .profile {
    padding: 0 10px;
    /* height: 70vh;
    overflow: auto; */
  }

  .header {
    margin-top: 4rem;
  }

  .flex {
    align-items: flex-start;
    width: 98%;
    margin: 0 auto;
  }

  .flex > div {
    background-color: #fff;
    min-height: 600px;
    width: 49%;
    border-radius: 20px;
    /* align-items: stretch; */
    box-shadow: 0 0 6px 2px #e8e8e8;
  }

  img {
    width: 100%;
  }

  .image-holder {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    overflow: hidden;
  }

  .profile-display {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* padding: 3rem 0; */
  }
  
  .item > div {
    margin-bottom: 20px;
  }

  .item .text {
    font-weight: bold;
  }

  .item .tag {
    font-size: 13px;
    color: #8d8d8d;
  }

  .profile-edit {
    background-color: #fff;
    padding: 20px 50px;
  }

  .fake-input {
        background: #fff;
        /* padding: 10px; */
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        width: 100%;
        padding: 0 10px;
        color: #000;
        border: 1px solid #a7a7a7;
    }

    .fake-input input {
      border: none;
      width: 100%;
      flex: 8;
      font-size: 15px;
    }

    .fake-input div {
      flex: 1;
    }

    .head {
      font-weight: bold;
      margin-bottom: 20px;
    }

    form > div {
      margin-bottom: 15px;
    }

    form > div:last-child {
      text-align: center;
    }


    form label {
      font-size: 12px;
      color: #a7a7a7;
    }

    form input, form select {
      margin-top: 2px;
      border: 1px solid #a7a7a7;
      font-size: 15px;  
      pointer-events: none;
    }
</style>