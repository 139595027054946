<template>
  <div class="entry">
      <div class="entry-box">
          <div class="top">
              <h3>Business</h3>
          </div>
          <div class="scroll">
          <div class="entry-item" @click="$emit('show-result', 'free')">
              <div class="image-spot">
                <div class="text">Basic Plan</div>
                <div class="small small-text">Free</div>
              </div>

              <div class="right">
                  <div class="checkbox">
                      <!-- Rounded Checkbox -->
                    <div class="round">
                        <input type="checkbox" id="checkbox" v-model="subscription.free" :checked="subscription.free"/>
                        <label for="checkbox"></label>
                    </div>
                  </div>
              </div>
          </div>
          <div class="entry-item"  @click="$emit('show-result', 'standard')">
              <div class="image-spot">
                <div class="text">Standard Plan</div>
                <div class="small small-text">$100 per month</div>
              </div>

              <div class="right">
                <!-- Rounded Checkbox -->
                <div class="round">
                    <input type="checkbox" id="checkbox2" :checked="!subscription.free && !subscription.premium" v-model="subscription.standard"/>
                    <label for="checkbox2"></label>
                </div>
              </div>
          </div>
          <div class="entry-item" @click="$emit('show-result', 'premium')">
              <div class="image-spot">
                <div class="text">Premium Plan</div>
                <div class="small small-text">$125 per month</div>
              </div>

              <div class="right">
                  <div class="checkbox">
                    <div class="checkbox">
                          <!-- Rounded Checkbox -->
                        <div class="round">
                            <input type="checkbox" id="checkbox3" :checked="!subscription.free && !subscription.standard" v-model="subscription.premium"/>
                            <label for="checkbox3"></label>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            subscription: {
                free: false,
                premium: true,
                standard: false
            }
        }
    }
}
</script>

<style scoped>
    img {
        width: 100%;
    }
    
    .top {
        padding: 15px 0;
        text-align: center;
        border-bottom: 3px solid #ccc;
        font-size: 14px;
    }

    .scroll {
        width: 100%;
        background-color: #fff;
        margin-top: 10px;
        height: 550px;
        overflow: auto;
        border-radius: 20px;
    }
    
    .scroll::-webkit-scrollbar {
        width: 0.2em;
    }
    
    /* .scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }
     */
    .scroll::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }

    .entry-box {
        padding: 10px 40px 20px 40px;
        overflow: hidden;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 0 6px 2px #e8e8e8;
        overflow: hidden;
        height: 600px;
    }

    .entry-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        padding-right: 20px;
        border-bottom: 1px solid #f7f6f6;
    }

    .image-spot .text {
        font-weight: 500;
        font-size: 14px;
    }

    .image-spot .small-text {
        font-size: 12px;
        color: #767676;
    }

    .checkbox .text {
        font-size: 12px;
        color: #434343;
    }

    .round {
        position: relative;
    }

    .round label {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        height: 20px;
        left: 0;
        position: absolute;
        top: 0;
        width: 20px;
    }

    .round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 3px;
        opacity: 0;
        position: absolute;
        top: 4px;
        transform: rotate(-45deg);
        width: 10px;
    }

    .round input[type="checkbox"] {
        visibility: hidden;
    }

    .round input[type="checkbox"]:checked + label {
        background-color: #66bb6a;
        border-color: #66bb6a;
    }

    .round input[type="checkbox"]:checked + label:after {
        opacity: 1;
    }

</style>