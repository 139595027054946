<template>
  <div class="entry">
      <div class="entry-box">
          <div class="scroll">
          <div class="entry-item" v-for="(notification, index) in notifications" :key="index" @click="$emit('show-data', { notification, type: notification.type})">
              <div class="image-spot">
                <div class="image-holder">
                    <img :src="notification.pictureUrl" alt="img" v-if="notification.pictureUrl">
                </div>
              </div>

              <div class="text">
                  <div class="name">{{ notification.firstName }} {{ notification.lastName }}</div>
                  <div class="tag">Resident enrollment request {{ notification.userId }}</div>
              </div>

              <div class="right"><div class="right">
                  <div class="time">
                    {{ new Date(notification.dateCreated).toLocaleTimeString('en-US').substring(0, 4) }}
                    {{ new Date(notification.dateCreated).toLocaleTimeString('en-US').substring(7, 10) }}
                </div>
                  <div class="dot" v-if="!notification.viewed"></div>
              </div></div>
          </div> 
          </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    created() {
        this.$store.dispatch('fetchNotifications');
    },
    computed: {
        ...mapGetters([
            'notifications'
        ])
    }
}
</script>

<style scoped>
    img {
        width: 100%;
    }

    .scroll {
        width: 100%;
        background-color: #fff;
        margin-top: 10px;
        max-height: 68vh;
        overflow: auto;
        border-radius: 20px;
    }

    .entry-box {
        padding: 0 20px 0 0;
        overflow: hidden;
        min-height: 780px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 0 6px 2px #e8e8e8;
        overflow: auto;
    }

    .entry-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid #f3f3f3;
    }

    .image-spot {
        flex: 0.7;
        margin-right: 10px;
    }

    .entry-item .text {
        flex: 10;
    }

    .entry-item .right {
        flex: 1;
    }

    .image-holder {
        width: 50px;
        height: 50px;
        border-radius: 100px;
        overflow: hidden;
        background-color:#DDF3FF;
    }

    .dot {
        background-color: #159661;
        height: 8px;
        width: 8px;
        border-radius: 100px;
        margin-top: 2px;
        float: right
    }

    .name {
        font-weight: bold;
        font-size: 14px;
    }

    .tag, .time {
        color: #a7a7a7;
        font-size: 12px;
    }
    
    .right {
        text-align: right;
    }
</style>