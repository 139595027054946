<template>
  <div class="entry">
      <h4 class="head">
          Entry Criteria
      </h4>

      <div class="options">
          <div class="item">
              <div class="text">
                  Everyone
              </div>
              <div class="selected">
                  <div class="checkbox c1">
                          <!-- Rounded Checkbox -->
                        <div class="round">
                            <input type="checkbox" id="checkbox" />
                            <label for="checkbox"></label>
                        </div>
                    </div>
              </div>
          </div>
          <div class="item">
              <div class="text">
                  Those in directory, only.
              </div>
              <div class="selected">
                  <div class="checkbox c1">
                          <!-- Rounded Checkbox -->
                        <div class="round">
                            <input type="checkbox" id="checkbox2" />
                            <label for="checkbox2"></label>
                        </div>
                    </div>
              </div>
          </div>
          <div class="item">
              <div class="text">
                  Those in...
              </div>
              <div class="selected">
                  <div class="checkbox c1">
                          <!-- Rounded Checkbox -->
                        <div class="round">
                            <input type="checkbox" id="checkbox3" />
                            <label for="checkbox3"></label>
                        </div>
                    </div>
              </div>
          </div>
          <div class="item">
              <div class="text">
                  Appointment
              </div>
              <div class="selected">
                  <div class="checkbox">
                        <!-- Rounded switch -->
                    <label class="switch">
                        <input type="checkbox">
                        <span class="slider round"></span>
                    </label>
                </div>
              </div>
          </div>
          <div class="item">
              <div class="text">
                  Valid means of identification
              </div>
              <div class="selected">
                  <div class="checkbox">
                        <!-- Rounded switch -->
                    <label class="switch">
                        <input type="checkbox">
                        <span class="slider round"></span>
                    </label>
                </div>
              </div>
          </div>
      </div>

      <div class="bottom">
            <Button 
            @click="$emit('open-box', 0)"
            :style="{ color: '#000', backgroundColor: '#f3f3f3', width: '176px', borderRadius: '8px', padding: '10px', fontWeight: '700' }" 
            text="DISMISS"
          />
          </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
    components: {
        Button
    },
    data(){
        return {
            
        }
    }
}
</script>

<style scoped>
    .entry  {
        padding: 10px;
    }

    .head {
        margin-bottom: 30px;
        text-align: center;
    }

    .options > div {
        padding: 10px;
        border-bottom: 1px solid #f3f3f3;
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
    }

    .bottom {
        margin-top: 7rem;
        text-align: center;
    }

    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 29px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 1.9px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        box-shadow: 0 1px 0px 1px #8f8f8f;
    }

    input:checked + .slider {
    background-color: #00b300;
    }

    input:focus + .slider {
    box-shadow: 0 0 1px #00b300;
    }

    input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
    border-radius: 34px;
    }

    .slider.round:before {
    border-radius: 50%;
    }

    .c1 .round {
        position: relative;
    }

    .c1 .round label {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        height: 20px;
        left: 0;
        position: absolute;
        top: 0;
        width: 20px;
    }

    .c1 .round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 3px;
        opacity: 0;
        position: absolute;
        top: 4px;
        transform: rotate(-45deg);
        width: 10px;
    }

    .c1 .round input[type="checkbox"] {
        visibility: hidden;
    }

    .c1 .round input[type="checkbox"]:checked + label {
        background-color: #66bb6a;
        border-color: #66bb6a;
    }

    .c1 .round input[type="checkbox"]:checked + label:after {
        opacity: 1;
    }

</style>