<template>
  <div class="entry">
      <div class="entry-box">
          <div class="scroll">
          <div class="entry-item" v-for="(user, index) in secondaryUsers" :key="index" @click="$emit('show-data', user)">
              <div class="image-spot">
                <div class="image-holder">
                    <img :src="user.pictureUrl" alt="img">
                </div>
              </div>

              <div class="text">
                  <div class="name">{{ user.firstName }} {{ user.lastName }}</div>
                  <!-- <div class="tag">@{{ user.tagName }}</div> -->
              </div>

              <div class="right">
                  <div class="time">{{ user.role }}</div>
              </div>
          </div>
          </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    created() {
        this.$store.dispatch('fetchSecondaryUsers')
    },
    computed: {
        ...mapGetters([
            'secondaryUsers'
        ])
    },
    data() {
        return {
     
        }
    }
}
</script>

<style scoped>
    img {
        width: 100%;
    }

    .scroll {
        width: 100%;
        background-color: #fff;
        height: 530px;
        overflow: auto;
        border-radius: 20px;
    }

    .entry-box {
        padding: 20px 20px 20px 10px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 0 6px 2px #e8e8e8;
        height: 580px;
    }

    .entry-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid #f3f3f3;
    }

    .image-spot {
        flex: 0.5;
        margin-right: 10px;
    }

    .entry-item .text {
        flex: 10;
    }

    .entry-item .right {
        flex: 1.2;
    }

    .image-holder {
        width: 50px;
        height: 50px;
        border-radius: 100px;
        overflow: hidden;
    }

    .dot {
        background-color: #159661;
        height: 8px;
        width: 8px;
        border-radius: 100px;
        margin-top: 2px;
        float: right
    }

    .name {
        font-weight: 600;
        font-size: 14px;
        text-transform: capitalize
    }

    .tag, .time {
        color: #a7a7a7;
        font-size: 12px;
    }
    
</style>